import React, { useEffect } from "react";

import ScrollReveal from "scrollreveal";
import $ from "jquery";

import { SectionHeading } from "./sectionHeading";

export default function Experience({theme}){
    useEffect(() => {
        ScrollReveal().reveal('.experience_content_companies_item.active', { origin: 'bottom', distance: '60px', easing: 'cubic-bezier(.5,0,0,1)', duration: 1500, desktop: false });
        ScrollReveal().reveal('.experience_content_descriptions_item.active', { origin: 'top', distance: '60px', easing: 'cubic-bezier(.5,0,0,1)', duration: 1500, desktop: false });
        ScrollReveal().reveal('.experience_content_companies_item.active', { origin: 'right', distance: '60px', easing: 'cubic-bezier(.5,0,0,1)', duration: 1500, mobile: false });
        ScrollReveal().reveal('.experience_content_descriptions_item.active', { origin: 'left', distance: '60px', easing: 'cubic-bezier(.5,0,0,1)', duration: 1500, mobile: false });

        $(".experience_content_companies_item").on("click", showDetails());
    }, []);

    const showDetails = () => {
        return(e) => {
            const id = e.target.id;

            $(".experience_content_companies_item").removeClass("active");
            $(".experience_content_descriptions_item").removeClass("active");

            const button = document.getElementById(id);
            const buttonOffset = button.offsetLeft;

            buttonOffset <= 50 ? $(".experience_content_companies").scrollLeft(0) : $(".experience_content_companies").scrollLeft(buttonOffset);

            $(button).addClass("active");
            $(`#${id}desc`).addClass("active");
        }
    }

    return(
        <section className={"experience " + theme} id="experience">
            <SectionHeading theme={theme} heading="experience"/>

            <div className="experience_content">
                <div className="experience_content_companies">
                    <button id="muntazah" className="experience_content_companies_item active">
                        Octedia 
                        <span>Dammam // KSA</span>
                    </button>

                    <button id="egt" className="experience_content_companies_item">
                        EGT
                        <span>Riyadh // KSA</span>
                    </button>

                    <button id="cristalcode" className="experience_content_companies_item">
                        Cristal Code
                        <span>Colombo // Sri Lanka</span>
                    </button>
                </div>

                <div className="experience_content_descriptions">
                    <div id="muntazahdesc" className="experience_content_descriptions_item active">
                        <h3>Full-stack .NET Developer</h3>
                        <label>October 2023 - Present</label>

                        <ul>
                            <li>
                                Worked with a team to develop the backend of the Mouwasat Hospital's mobile application, which received the highest app review ratings in the KSA
                            </li>
                            <li>
                                Worked on the integration of NPHIES R2, Saudi Arabia’s centralized clinical system, adhering to HL7 and FHIR standards
                            </li>
                            <li>
                                Collaborated with a multidisciplinary team to develop secure online doctor consultation platforms, enhancing the delivery of virtual healthcare services
                            </li>
                            <li>
                                Assisted in managing and optimizing SQL Server and Oracle databases to ensure the reliability and performance of healthcare applications
                            </li>
                        </ul>
                    </div>

                    <div id="egtdesc" className="experience_content_descriptions_item">
                        <h3>Full-stack Developer (.NET & React)</h3>
                        <label>October 2022 - October 2023</label>

                        <ul>
                            <li>
                                Built RESTful APIs using .NET that serve data to the React front end and handle over 10,000 users
                            </li>
                            <li>
                                Single-handedly developed and documented the backend for a client CRM system using .NET, SQL Server, and Hangfire for CRON jobs
                            </li>
                            <li>
                                Designed and developed the company website using WordPress and a custom theme built using PHP and Sass, which saw a 50% increase in lead generation and conversion
                            </li>
                            <li>
                                Worked with mobile developers to build a mobile app using React Native that utilizes OCR
                            </li>
                            <li>
                                Responsible for maintaining communication with clients for requirements gathering, regularly providing and showcasing progress during the project, and taking client feedback and relaying it to the project team
                            </li>
                        </ul>
                    </div>

                    <div id="cristalcodedesc" className="experience_content_descriptions_item">
                        <h3>Software Engineer Intern</h3>
                        <label>November 2020 - November 2021</label>

                        <ul>
                            <li>
                                Wrote modern, performant, and robust code for clients
                            </li>
                            <li>
                                Collaborated with a small team to build software solutions for local businesses
                            </li>
                            <li>
                                Developed robust solutions and rapidly prototyped UIs using a variety of languages, frameworks and database technologies such as ASP.NET, Electron, Node js and SQL Server
                            </li>
                            <li>
                                Built and shipped a robust CMS and POS system as a single multi-platform desktop application
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}